<template>
	<main class="page-container">
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<div v-if="isMobile" class="box_select is-static">
			<button type="button" id="select_btn" @click="getSCommunityInfo()">커뮤니티</button>
			<ul id="select_list">
				<li v-for="(item, idx) in menus" :key="idx">
					<a :href="item.path">- {{ item.title }}</a>
				</li>
			</ul>
		</div>
		<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
		<section class="scomm-section">
			<div class="container">
				<!-- 상단 제목 검색 -->
				<div class="scomm-detail-header">
					<h2 class="scomm-detail-header__title">{{ this.scommunityItem.cmntNm }}</h2>
					<div class="scomm-main-search" @keyup.enter="setPostSearchParam('S', 'A')">
						<fieldset>
							<legend class="sr-only">커뮤니티 검색</legend>
							<input v-model="initSearchText" type="search" class="scomm-main-search__input" placeholder="검색어를 입력해 보세요!  " />
							<button type="submit" class="scomm-main-search__submit" @click="setPostSearchParam('S', 'A')"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
						</fieldset>
					</div>
				</div>
				<!-- //상단 제목 검색 -->
				<div class="scomm-detail-flex">
					<!-- s: 왼쪽 영역 -->
					<div class="flex-0">
						<div class="scomm-detail-info__thumb">
							<img v-if="this.scommunityItem.rprsImgPtUrl" :src="this.scommunityItem.rprsImgPtUrl" alt="사진" />
							<img v-else src="" alt="" />
							<!-- <img src="~@/assets/img/@tmp/tmp_notice_image_02@3x.jpg" alt="사진" /> -->
						</div>
						<!-- 웹접근성 구현 탭 -->
						<Tabs>
							<TabList>
								<Tab>커뮤니티정보</Tab>
								<Tab>나의 활동</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<!-- 커뮤니티정보 -->
									<div class="scomm-detail-info__panel">
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-member"></i>
												<span class="text">멤버수</span>
											</dt>
											<dd>{{ this.scommunityItem.cmntUserCnt }}명</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-board"></i>
												<span class="text">게시물 수</span>
											</dt>
											<dd>{{ this.scommunityItem.cmntPostCnt }}건</dd>
										</dl>
									</div>
									<!-- //커뮤니티정보 -->
								</TabPanel>
								<TabPanel>
									<!-- 나의 활동 -->
									<div v-if="this.scommunityUserAtbt.rgtrYn === 'Y'" class="scomm-detail-info__panel">
										<!-- 사용자 정보 -->
										<div class="scomm-detail-user">
											<div class="scomm-detail-user__photo">												
												<img v-if="session.profileImg" :src="session.profileImg" alt="사진" />
												<!-- <img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="사진" /> -->
												<img v-else src="" alt="" />
											</div>
											<div class="scomm-detail-user__text">
												<span class="scomm-detail-user__name">{{this.scommunityUserAtbt.userNm}}</span>
												<span class="scomm-detail-user__date">{{regDtTimeFormat(this.scommunityUserAtbt.regDt)}}</span>
											</div>
										</div>
										<!-- //사용자 정보 -->
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-user"></i>
												<span class="text">방문</span>
											</dt>
											<dd>{{this.scommunityUserAtbt.vstCnt}}회</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-myarticle"></i>
												<span class="text"><a @click="setPostSearchParam('S', 'W')">내가 쓴 글 보기</a></span>
											</dt>
											<dd>{{this.scommunityUserAtbt.cmntUserPostCnt}}개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-mycomm"></i>
												<span class="text"><a @click="setPostSearchParam('S', 'R')">내가 쓴 댓글 보기</a></span>
											</dt>
											<dd>{{this.scommunityUserAtbt.cmntUserRplyCnt}}개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-like"></i>
												<span class="text"><a @click="setPostSearchParam('S', 'L')">좋아요 한 글 보기</a></span>
											</dt>
											<dd>{{this.scommunityUserAtbt.cmntUserLikCnt}}개</dd>
										</dl>
									</div>
									<!-- //나의 활동 -->
								</TabPanel>
							</TabPanels>
						</Tabs>
						<template v-if="this.userJoinShow">
							<br v-if="this.scommunityUserAtbt.rgtrYn === 'Y'" />
							<a v-else-if="this.scommunityUserAtbt.rgtrYn === 'S'" href="javascript:void(0);" class="button-default is-rounded is-primary">
								<span class="is-desktop">활동정지</span>
								<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
							</a>
							<a v-else-if="this.scommunityUserAtbt.rgtrYn === 'D'" href="javascript:void(0);" class="button-default is-rounded is-primary">
								<span class="is-desktop">가입대기중</span>
								<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
							</a>
							<button v-else @click="joinSCommunityUser" class="button-default is-rounded is-primary">
								<span class="is-desktop">가입하기</span>
								<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
							</button>
						</template>
						<nav class="scomm-detail-info__links">
							<ul>
								<li>
									<a @click="setPostSearchParam('A', '')"><i class="icon-info-home"></i><span>Home</span></a>
								</li>
								<li v-for="(item, idx) in this.scommunityBrdMnts" :key="idx">
									<a @click="setPostSearchParam(item.cmntBrdTyp == 'NOTICE' ? 'N' : 'F', '')"><i class="icon-info-notice"></i><span>{{ item.cmntBrdNm }}</span></a>
								</li>
							</ul>
						</nav>										
					</div>
					<!-- e: 왼쪽 영역 -->
					<!-- s: 오른쪽 영역 -->
					<div v-if="this.pageType == 'P'" class="flex-1">
						<!-- 공지사항 -->
						<section v-if="this.scommunityBrdNotice" class="scomm-detail-notice">
							<div class="scomm-detail-notice__header">
								<h3 class="scomm-detail-notice__title">공지사항</h3>
								<a class="scomm-detail-notice__more" @click="setPostSearchParam('N', '')">더보기</a>
							</div>
							<div class="scomm-detail-notice__content">
								<ul>
									<li v-for="(item, idx) in this.scommunityBrdNotice" class="scomm-detail-notice__item" :key="idx">
										<a class="scomm-detail-notice__link" @click="setPostSearchParam('N', item.cmntBrdId)">
											<span class="scomm-detail-notice__tag">공지</span>
											<strong>{{ item.cmntBrdTit }}</strong>
											<!-- <em>[{{ item.cmntBrdViewCnt }}]</em> -->
										</a>
										<span class="scomm-detail-notice__count">{{ item.cmntBrdViewCnt }}</span>
									</li>
									<div v-if="this.noticeEmpty" class="no-results-found">
										<p class="text">등록된 공지사항이 없습니다.</p>
									</div>									
								</ul>
							</div>
						</section>
						<!-- //공지사항 -->
						<!-- 게시글 등록 -->
						<div v-if="this.scommunityUserAtbt.rgtrYn === 'Y' && !this.adminYn" class="scomm-detail-write">
							<fieldset>
								<legend class="sr-only">게시글 등록</legend>
								<textarea v-model="postCn" id="txtPostCn" v-on:input="postCntyping" cols="30" rows="10" class="textarea" maxlength="1000"></textarea>
								<div class="scomm-detail-write__footer">
									<div class="flex-l">
										<span class="scomm-detail-write__upload" v-show="previewImage">
											<em class="thumb">
												<img :src="previewImage" />
											</em>
											<button type="button" class="button-delete" @click="cancelUpload">
												<span class="sr-only">이미지 삭제</span>
											</button>
										</span>
										<span class="button-upload-photo" v-show="!previewImage">
											<label>
												<input ref="fileInput" type="file" accept="image/*" @change="onFileSelected()" />
												<i class="icon-detail-photo"></i>
												<span class="sr-only">이미지등록</span>
											</label>
										</span>

										<span class="scomm-detail-write__footer-count">{{ this.postCnLength }}/1000</span>
									</div>
									<div class="flex-r">
										<button type="submit" class="button-default" @click="insertSCommunityPost">등록</button>
									</div>
								</div>
							</fieldset>
						</div>
						<!-- //게시글 등록 -->
						<!-- 게시글 -->
						<section class="scomm-detail-list">
							<h3 class="sr-only">게시글 목록</h3>
							<ul class="scomm-detail-articles">
								<li v-for="(item, idx) in this.scommunityPosts" :key="idx" :class="{ 'is-pinned': item.topYn == 'Y' }">
									<s-community-post :post-item="item" :cmnt-user-status="scommunityUserAtbt.rgtrYn" :cmntAdminYn="adminYn" @getSCommunityPostList="getSCommunityPostList" />
								</li>
								<div v-if="this.postEmpty" class="no-results-found">
									<p class="text">등록된 게시글이 없습니다.</p>
								</div>
							</ul>
						</section>
					</div>
					<s-community-post-search v-else-if="this.pageType == 'S'" :scommunityNo="scommunityNo" :cmntAdminYn="adminYn" :scommunityUserAtbt="scommunityUserAtbt" :initSearchPostType="initSearchPostType" :initSearchText="initSearchText" />
					<s-community-board v-else-if="this.pageType == 'N' && this.scommunityNoticeNo != 0" :scommunityNo="scommunityNo" :scommunityBrdMntId="scommunityNoticeNo" :scommunityBrdInfo="scommunityNotice" :initBrdNoticeNo="initBrdNoticeNo" />
					<s-community-board v-else-if="this.pageType == 'F' && this.scommunityFileBrdNo != 0" :scommunityNo="scommunityNo" :scommunityBrdMntId="scommunityFileBrdNo" :scommunityBrdInfo="scommunityFileBrd" :initBrdNoticeNo="initBrdNoticeNo"/>
					<!-- e: 오른쪽 영역 -->
				</div>
			</div>
		</section>
		<terms-agree-modal v-show="termsShow" @insertSCommunityUser="insertSCommunityUser" @termsShowClose="termsShowClose" />
	</main>
</template>

<script>
import menus from '../../../assets/js/menus';
import { mapGetters } from 'vuex';
import SCommunityPost from '../../../components/scommunity/SCommunityPost.vue';
import SCommunityPostSearch from '../../../components/scommunity/SCommunityPostSearch.vue';
import SCommunityBoard from '../../../components/scommunity/SCommunityBoard.vue';
import PortalPagination from '../../../components/PortalPagination';
import TermsAgreeModal from '@/components/scommunity/TermsAgreeModal.vue';
import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';
import { ACT_GET_SCOMMUNITY_INFO, ACT_GET_SCOMMUNITY_USER_ATBT, ACT_GET_SCOMMUNITY_BRD_MNT, 
	ACT_INSERT_SCOMMUNITY_USER, ACT_GET_SCOMMUNITY_BRD_LIST, ACT_GET_SCOMMUNITY_POST_LIST,
	ACT_INSERT_SCOMMUNITY_POST,	ACT_UPLOAD_SCOMMUNITY_POST_IMG,	ACT_GET_SCOMMUNITY_PRHB_WRDS,
} from '../../../store/_act_consts';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SET_SCOMMUNITY_PRHB_WRDS } from '../../../store/_mut_consts';
import {getItem, getItems, lengthCheck, timestampToDateFormat, getCheckItems, getResult, setPaging} from '../../../assets/js/utils';

export default {
	name: 'ScommunityHome',
	components: {
		SCommunityPost,
		SCommunityPostSearch,
		SCommunityBoard,
		PortalPagination,
		Tabs,
		Tab,
		TabList,
		TabPanels,
		TabPanel,
		TermsAgreeModal,
	},
	data: () => ({
		menus: menus,
		hasMore: false,
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,
		previewImage: null,
		scommunityNo: 0,
		scommunityItem: {},
		scommunityUserAtbt: {},
		scommunityBrdMnts: [],
		scommunityNoticeNo: 0,
		scommunityNotice: {},
		scommunityFileBrdNo: 0,
		scommunityFileBrd: {},
		scommunityBrdNotice: [],
		initBrdNoticeNo: 0,
		scommunityPosts: [],
		postCn: '',
		postCnLength: 0,
		noticeEmpty: false,
		postEmpty: false,
		userJoinShow: false,
		pageType: 'P',	//P : Post, S : Post Search, N : Notice, F : File Board
		initSearchPostType: 'A',
		initSearchText: '',
		postSearchParam: {
			dateType: 'A',
			startYmd: '',
			endYmd: '',
			srchPost: 'A',
			srchType: 'A',
			srchText: '',
			userId: 0,
			pageNo: 1,
			pageSize: 10,
		},
		adminYn: false,
		termsShow: false,
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('scommunity', ['prhbWrds']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {
		session: function (newVal, oldVal) {
			if (typeof newVal.userId != 'undefined') {
				if(this.scommunityItem.cmntOwnId == newVal.userId || this.scommunityItem.cmntSOwnId == newVal.userId){
					this.adminYn = true;
				} else {
					this.adminYn = false;
				}
			}
			
			this.getSCommunityUserAtbt();
			this.getSCommunityPostList(1);
		},
		scommunityBrdMnts: function(brdMnts){
			brdMnts.forEach(element => {
				if(element.cmntBrdTyp === 'NOTICE'){
					this.getSCommunityBrdNoticeTop(element.cmntBrdMntId);
					this.scommunityNoticeNo = element.cmntBrdMntId;
					this.scommunityNotice = element;
				} else if(element.cmntBrdTyp === 'FILEBRD'){
					this.scommunityFileBrdNo = element.cmntBrdMntId;
					this.scommunityFileBrd = element;
				}
			});
		},
	},
	created() {
		this.scommunityNo = this.$route.params.cmntId;
		this.getSCommunityInfo();
		this.getSCommunityBrdMntList();
		this.getSCommunityUserAtbt();
		this.getSCommunityPostList(1);
		if(this.prhbWrds.length == 0){
			this.getSCommunityPrhbWrds();
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleListScroll);
	},
	unmounted(){
		window.removeEventListener('scroll', this.handleListScroll);
	},
	methods: {
		onFileSelected() {
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.previewImage = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		cancelUpload() {
			this.previewImage = null;
			this.$refs.fileInput.value = null;
		},
		getSCommunityInfo(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_INFO}`, this.scommunityNo).then(res => {
				this.scommunityItem = getItem(res);
				if (typeof this.session.userId != 'undefined') {
					if(this.scommunityItem.cmntOwnId == this.session.userId || this.scommunityItem.cmntSOwnId == this.session.userId){
						this.adminYn = true;
					} else {
						this.adminYn = false;
					}
				}
			});
		},
		getSCommunityUserAtbt(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_USER_ATBT}`, {
				cmntId: this.scommunityNo,
				params: {
					userId: typeof this.session.userId == 'undefined' ? 9999999 : this.session.userId,
				}
			}).then(res => {
				if(lengthCheck(res)){
					this.scommunityUserAtbt = getItem(res);
					//this.userJoinShow = true;
				}
				
				this.userJoinShow = typeof this.session.userId == 'undefined' ?	false : true;	
			});
		},
		getSCommunityBrdMntList(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_BRD_MNT}`, this.scommunityNo).then(res => {
				this.scommunityBrdMnts = getCheckItems(res);
			});
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd');
		},
		joinSCommunityUser(){
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `'${this.scommunityItem.cmntNm}' 에 가입을 신청하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					//this.insertSCommunityUser();
					this.termsShow = true;
				},
				nofunc: () => {
					this.isLoading = false;
				},
			});
		},
		insertSCommunityUser(){
			this.termsShow = false;
			if (typeof this.session.userId == 'undefined') {
				alert('로그인이 필요합니다.');
				this.$router.push({ name: 'Login' });
				return false;
			} else {
				this.$store.dispatch(`scommunity/${ACT_INSERT_SCOMMUNITY_USER}`, {
					cmntId: this.scommunityNo,
					params: {
						userId: this.session.userId,
						userNm: this.session.userNm,
						userTyp: 'user',
					}
				}).then(res => {
					if(getResult(res).number === 200){
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: `'${this.scommunityItem.cmntNm}' 에 가입 신청이 완료되었습니다. 관리자 승인 후에 이용 가능합니다.`});
						this.getSCommunityUserAtbt();
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},
		getSCommunityBrdNoticeTop(brdMntId){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_BRD_LIST}`, {
				cmntId: this.scommunityNo,
				cmntBrdMntId: brdMntId,
				params: {
					pageNo: 1,
					pageSize: 3,
				}
			}).then(res => {
				if(lengthCheck(res)){
					this.scommunityBrdNotice = getItems(res);
				} else {
					this.noticeEmpty = true;
				}				
			});
		},
		getSCommunityPostList(no){
			// postSearchParam 설정
			this.postSearchParam.userId = this.session.userId;
			this.postSearchParam.pageNo = no;
			this.postSearchParam.pageSize = this.pageSize;

			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_POST_LIST}`, {
				cmntId: this.scommunityNo,
				params: this.postSearchParam,
			}).then(res => {
				if(no === 1){
					if(lengthCheck(res)){
						this.scommunityPosts = getItems(res);
					} else {
						this.postEmpty = true;
					}					
				} else {
					const addPosts = getCheckItems(res);
					addPosts.forEach(element => {
						this.scommunityPosts.push(element);
					});					
				}				
				setPaging(this, res);
			});
		},
		async insertSCommunityPost(){
			if(this.postCn.length > 0) {
				if(!this.validatePrhbWrds(this.postCn)){
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '작성내용에 금칙어가 포함되어 있습니다.'});
					return;
				}

				let imgPath = '';
				if (this.$refs.fileInput && this.$refs.fileInput.files && this.$refs.fileInput.files.length > 0) {
					const imgRes = await this.$store.dispatch(`scommunity/${ACT_UPLOAD_SCOMMUNITY_POST_IMG}`, { cmntId: this.scommunityNo, fileList: this.$refs.fileInput.files });
					if (imgRes.data.result.number !== 200) {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '포스트 이미지는 이미지 파일만 업로드 가능합니다.'});
						return;
					}

					if (imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200) {
						imgPath = imgRes.data.filePath;
					}

					this.$refs.fileInput.value = null;
				}

				this.$store.dispatch(`scommunity/${ACT_INSERT_SCOMMUNITY_POST}`, {
					cmntId: this.scommunityNo,
					params: {
						cmntPostCn: this.postCn,
						cmntPostAtch: imgPath,
						userId: this.session.userId,
					}
				}).then(res => {
					if(getResult(res).number === 200){
						this.getSCommunityPostList(1);
						this.scommunityItem.cmntPostCnt = this.scommunityItem.cmntPostCnt + 1;
						this.postCn = '';
						this.postCnLength = 0;
						this.previewImage = null;
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
					}							
				});
			}
		},		
		postCntyping : function(e){        
            this.max_length(e, 1000);
        },
        max_length : function(e, len){
            const val =  e.target.value;

			let byte = 0;
			let txtLength = 0;
			for (txtLength = 0; txtLength < val.length; txtLength++) {
				const code = val.charCodeAt(txtLength);
				let charByte = 0;
				if(code > 127){
					charByte = 2;
					byte += charByte;
				} else if(code > 64 && code < 91){
					charByte = 2;
					byte += charByte;
				} else {
					charByte = 1;
					byte += charByte;
				}

				if(byte > len){
					//txtLength = txtLength - 1;
					byte -= charByte;
					break;
				}
			}

			this.postCn = val.substring(0, txtLength);
			this.postCnLength = byte;		
        },
		getByteLength : function(str){
			let byte = 0;
			for (var idx = 0; idx < str.length; idx++) {
				const code = str.charCodeAt(idx);
				if(code > 127){
					byte += 2;
				} else if(code > 64 && code < 91){
					byte += 2;
				} else {
					byte += 1;
				}
			}
			return byte;
		},
		// 무한 스크롤 정의
		handleListScroll(e) {
			const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
			const isAtTheBottom = scrollHeight === scrollTop + clientHeight;
			// 일정 한도 밑으로 내려오면 함수 실행 && 추가 페이지가 있을때.
			if (isAtTheBottom && this.hasMore) this.handleLoadMore();
		},
		// 내려오면 api 호출하여 아래에 더 추가, total값 최대이면 호출 안함
		handleLoadMore() {
			this.pageNo += 1;
			this.getSCommunityPostList(this.pageNo);
		},
		setPostSearchParam(type, postType) {
			if(type == 'A'){ // 전체
				this.pageType = 'P';
				this.postSearchParam.dateType = 'A';
				this.postSearchParam.startYmd = '';
				this.postSearchParam.endYmd = '';
				this.postSearchParam.srchPost = 'A';
				this.postSearchParam.srchType = 'A';
				this.postSearchParam.srchText = '';
				this.initSearchPostType = 'A';
				this.initSearchText = '';
			} else if(type == 'S'){ // 포스트 검색
				this.pageType = 'S';
				this.initSearchPostType = postType;
			} else if(type == 'N'){ // 공지사항
				this.initBrdNoticeNo = 0;
				this.pageType = 'N';
				if(postType != ''){
					this.initBrdNoticeNo = postType;
				}
				//this.initSearchPostType = postType;
			} else if(type == 'F'){ // 자료실
				this.initBrdNoticeNo = 0;
				this.pageType = 'F';
				if(postType != ''){
					this.initBrdNoticeNo = postType;
				}
				//this.initSearchPostType = postType;
			} 
			// else {
			// 	this.pageType = 'S';
			// 	this.initSearchPostType = postType; // L : 내가 좋아요 한 글, W : 내가 쓴 글, R : 내가 쓴 댓글
			// }
		},
		getSCommunityPrhbWrds(){
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_PRHB_WRDS}`, {}).then(res => {
				if(lengthCheck(res)){
					this.$store.commit(`scommunity/${MUT_SET_SCOMMUNITY_PRHB_WRDS}`, getItems(res));
				}
			});
		},
		validatePrhbWrds(cn){
			// 금칙어 벨리데이션
            const prhbWrds = this.prhbWrds.map((item, index) => item.prhbWrd);
			let newSrchWrd = new Array();
            for(let i = 0; i < prhbWrds.length; i++){
				const findTrue = cn.includes(prhbWrds[i])
				newSrchWrd.push(findTrue);
			}

            if(newSrchWrd.includes(true)){
                //Swal.fire({text:"검색어에 금칙어가 포함되어 있습니다."});
                return false;
            } else {
				return true;
			}

		},
		termsShowClose(){
			this.termsShow = false;
		},
	},
};
</script>
