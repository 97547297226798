<template>
	<main class="layer-popup-container">
		<div class="layer-popup layer-popup-terms">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">가치삽시다 커뮤니티 이용정책</h3>
						<button type="button" class="btn-close" @click="termsShowClose()">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body">
						<!-- s: 이용정책 내용 -->
						<div class="terms-wrap">
							<section class="terms-section">
								<h4 class="terms-section-title">1. 커뮤니티 정책의 개요</h4>
								<p>본 서비스 약관(이하 "약관")은 이용자가 가치삽시다(이하 "가치삽시다" 혹은 "당사")의 서비스(이하 "서비스" 또는 "커뮤니티")뿐만 아니라 가치삽시다 커뮤니티상에서 업로드 또는 다운로드되거나 보여지는 정보, 문구, 그래픽, 사진 또는 기타 자료에 접근 및 조회하여 이를 이용하는 행위 일체에 적용됩니다. 이용자는 가치삽시다를 이용하시기 전 본 약관을 숙독하셔야 합니다. 이용자가 가치삽시다에 접속하고 이용하는 행위는 이용자가 본 약관에 동의하고 이에 준수하는 것을 의미합니다. 이용자는 본 약관을 거부할 권리가 있습니다. 다만, 본 약관을 거부하실 경우, 가치삽시다 이용이 제한될 수 있습니다. 또한 가치삽시다는 구체적인 법적 분쟁에 대한 사법적 판단과 중재 활동은 하지 않습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">2. 기본 약관</h4>
								<p>2.1. 가치삽시다는 소상공인 지원사업에 선정된 소상공인과 지원사업 담당자가 자유롭게 의견을 나눌 수 있도록 가치삽시다 내 커뮤니티 기능을 제공합니다. 이에 따라 가치삽시다는 커뮤니티 내 게시물 및 이용자에 대해 운영 및 제한할 권리를 가집니다. 서비스의 사용과 서비스에 게시하는 콘텐츠, 그에 따른 결과에 대한 책임은 이용자에게 있습니다. 이용자가 커뮤니티에 게시, 표시하는 콘텐츠는 동일한 커뮤니티에 콘텐츠를 제출, 게시, 표시할 권리가 있는 다른 이용자가 열람할 수 있습니다. 이용자는 본 약관에 따라 다른 사람들과 공유하여도 불편하지 않은 콘텐츠만을 제공하여야 합니다. 이용자는 서비스를 이용하거나 콘텐츠를 게시함으로써 당사의 커뮤니티 가이드라인을 검토하고 준수할 것에 동의합니다.</p>

								<p>2.2. 가치삽시다가 제공하는 커뮤니티는 운영 및 개선의 목적, 혹은 가치삽시다가 적절하다고 판단하는 사유에 의거하여 (사전통지 없이) 변경하거나 종료할 수 있습니다. 가치삽시다가 제공하는 본 서비스의 형태 및 성격은 이용자에 대한 사전 통지 없이 수시로 변경될 수 있습니다. 당사는 또한 당사의 단독 재량에 따라 언제든지 이용자에 대한 사전 통지 없이 이용 및 저장에 대한 제한을 설정할 수 있는 권리를 보유합니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">3. 이용자 정의</h4>
								<p>가치삽시다 커뮤니티에서 정의하는 이용자의 분류는 다음과 같습니다.</p>
								<strong class="title">3.1. 이용자</strong>
								<p>해당 지원사업(커뮤니티명)에 선정된 소상공인 및 소속 근로자로, 사업자등록번호, 회사명, 핸드폰번호 입력을 통해 본인인증이 완료된 사용자입니다.</p>
								<strong class="title">3.2. 담당자</strong>
								<p>해당 지원사업의 관리담당자로, 중소기업유통센터 및 전문기관 담당자를 일컫습니다.</p>
								<strong class="title">3.3. 관리자</strong>
								<p>가치삽시다 웹사이트를 총괄하는 관리자를 일컫습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">4. 제공하는 서비스 종류</h4>
								<p>가치삽시다 커뮤니티에서 제공하는 기능은 다음과 같습니다.</p>
								<ul>
									<li>(ⅰ) 소상공인 지원사업 선정 소상공인 대상 공지사항</li>
									<li>(ⅱ) 지원사업 참여 소상공인 간 자유로운 의견 공유를 위한 자유게시판</li>
									<li>(ⅲ) 기타 지원사업 운영에 필요한 각종 기능(설문조사 등의 투표기능, 이벤트 등)</li>
								</ul>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">5. 커뮤니티 운영관련</h4>
								<strong class="title">5.1. 가입방법</strong>
								<p>본 커뮤니티는 중소기업유통센터의 지원사업에 신청하여 선정된 소상공인을 대상으로 운영하며, 사업자등록번호와 업체명, 가입에 필요한 개인정보(핸드폰번호, 이메일 등)를 입력한 후, 담당자로부터 커뮤니티 가입 승인을 받은 소상공인 회원만 커뮤니티를 이용할 수 있습니다.</p>
								<strong class="title">5.2. 운영기간</strong>
								<p>커뮤니티는 기본적으로 지원사업이 운영되는 기간동안 운영됩니다. 사업 운영이 종료되면, 해당 지원사업 커뮤니티 또한 종료되며, 종료된 커뮤니티에 작성한 게시글은 수정 및 삭제 불가합니다.</p>
								<strong class="title">5.3. 회원정보변경</strong>
								<p>이용자는 마이페이지를 통해 언제든지 자신의 정보를 변경할 수 있습니다. 단, 커뮤니티 이용에 필요한 필수정보(사업자등록번호, 회사명)은 변경할 수 없습니다.</p>
								<strong class="title">5.4. 회원탈퇴</strong>
								<p>탈퇴 후 탈퇴 회원의 게시물은 삭제되지 않으며, 회원정보 삭제로 인해 작성자 본인을 확인할 수 없으므로 게시물 삭제 처리가 원천적으로 불가능합니다. 따라서 게시물 삭제를 원하시는 경우에는 먼저 해당 게시물을 삭제한 후, 탈퇴를 신청하시기 바랍니다.</p>
								<strong class="title">5.5. 개인정보보호의무</strong>
								<p>가치삽시다는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 가치삽시다의 개인정보취급방침이 적용됩니다. 다만, 가치삽시다의 공식 사이트 이외의 링크된 사이트에서는 가치삽시다의 개인정보취급방침이 적용되지 않습니다.</p>
								<strong class="title">5.6 비밀번호</strong>
								<p>이용자가 커뮤니티에 접속하기 위하여 이용하는 비밀번호의 보호 및 이용자의 비밀번호를 이용한 활동이나 행위 일체에 대한 책임은 이용자에게 있습니다. 당사는 이용자에게 "강력한" 비밀번호(대∙소문자, 숫자 및 특수문자를 조합한 형태의 비밀번호)를 설정할 것을 권장합니다. 가치삽시다는 관계법령 및 ‘개인정보보호 정책’에 의해서 그 책임을 지는 경우를 제외하고, 이용자가 권장된 내용을 이행하지 않아 발생하는 손실이나 손해에 대한 책임을 부담하지 않으며, 이는 향후에도 마찬가지입니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">6. 콘텐츠 관련</h4>
								<strong class="title">6.1. 콘텐츠 </strong>
								<p>이용자가 다른 이용자가 볼 수 있도록 공개된 커뮤니티에 게재한 문자, 그림, 사진, 동영상, 링크, 음성 등으로 구성된 모든 종류의 콘텐츠를 의미합니다.</p>
								<strong class="title">6.2. 소유권 </strong>
								<p>콘텐츠에 대한 일체의 권리 및 소유권은 콘텐츠 작성자에게 있습니다. 단, 이용자의 콘텐츠를 사용, 복사, 수행, 전시, 파생물 제작, 개조 및 배포할 수 있는 무제한의, 취소 불가한, 영구적인, 비 독점적인, 로열티 무료의 라이센스를 가치삽시다에 부여합니다.</p>
								<p>이용자는 본 서비스를 이용하는 과정에서 불쾌하거나, 유해하거나, 부정확하거나, 부적절하거나, 허위제목으로 등록되었거나, 기만적인 게시물에 노출될 수도 있음을 인지하고 있습니다. 가치삽시다는 어떠한 경우에도, 이용자가 등록한 콘텐츠의 신뢰성에 대한 책임을 부담하지 않으며, 본 서비스를 통해 게시되거나, 이메일 등의 방식으로 전송되거나, 서비스를 통해 제공되거나, 타 매체에 게시됨으로 인해서 발생하는 종류를 불문한 손실이나 손해 일체에 대한 책임을 부담하지 않습니다.</p>
								<strong class="title">6.3. 제한권리</strong>
								<p>담당자 및 관리자는 이용자가 작성한 컨텐츠를 숨김 및 삭제하거나, 서비스 이용을 제한 혹은 특정 닉네임 이용을 제한하기 위한 권리를 갖고 있습니다. 이 약관에 따라 아래에 해당하는 행위를 한 소상공인의 계정에 대해서는 서비스 이용이 제한될 수 있습니다.</p>
								<ul>
									<li>(ⅰ) 불법적/차별적/악의적인 사항에 대한 논의 선동행위.</li>
									<li>(ⅱ) 특정인의 명예훼손, 위협, 희롱하는 등의 행위나 개인정보보호 및 지적재산권 등의 법적인 권리를 위반하는 행위.</li>
									<li>(ⅲ) 소통공간에 적합하지 않은 공격적인, 포르노성의, 위협적인, 공포스러운 류의 이미지 게재행위.</li>
									<li>(ⅳ) 기타 가치삽시다 제공 서비스의 제작 의도 등에 반하는 내용의 게재행위.</li>
								</ul>
								<p>6.5. 관리자는 콘텐츠를 모니터링하며 악의적이나 불법적인 콘텐츠를 없애기 위해 노력하며, 언제든지 이용자의 가치삽시다 이용 권리를 제한하거나 해지시킬 수 있습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">7. 보증거부</h4>
								<p>7.1. 서비스에 접속하거나 서비스를 이용하는 이용자는 공격적이거나 외설적이거나 불쾌할 수 있는 타인의 자료에 노출될 수 있음을 인정 및 동의하고 이러한 위험을 감수하는 데 동의해야 합니다. 커뮤니티에 게시하는 의견은 당사의 의견을 반영하지 않습니다. 또한 당사는 이용자가 게시하는 콘텐츠를 지지 또는 보증하지 않습니다.</p>
								<p>7.2. 당사는 이용자의 콘텐츠를 보호하기 위해 합리적인 보안 조치를 제공하지만 이용자의 콘텐츠 공개로 발생하는 손해에 대해 책임지지 않습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">8. 커뮤니티 정책 위반에 대한 신고 절차</h4>
								<p>가치삽시다 커뮤니티 회원(이용자·담당자·관리자)라면 누구나 커뮤니티 질서를 저해하는 행위를 신고할 수 있습니다. 가치삽시다는 접수된 신고 내역을 신속하게 확인하고 서비스를 이용하는 회원들을 보호하기 위하여 신고 및 조치 결과에 대하여 공개할 수 있습니다.</p>
								<strong class="title">8.1. 신고서 접수</strong>
								<p>게시물에 있는 신고하기를 통해 신고 접수가 가능하며 신고 접수가 어려운 경우 가치삽시다 고객센터를 통해 신고서를 접수할 수 있습니다.</p>
								<p>* 가치삽시다 고객센터 : 1899-0309, 이메일 : -----------------@sbdc.or.kr</p>
								<strong class="title">(1) 공통 기재사항</strong>
								<p>신고인은 가치삽시다 또는 피신고인이 신고 내용에 대해 연락을 취할 수 있도록 한 개 이상의 연락처 정보를 제공해야 합니다. 연락처 정보에는 성명, 이메일, 핸드폰 번호가 포함됩니다. 연락처 제공이 되지 않을 경우, 가치삽시다 등록시 기입한 핸드폰 번호로 연락을 취할 수 있습니다.</p>
								<strong class="title">(2) 신고 근거</strong>
								<p>신고인은 커뮤니티 정책 위반 사실을 구체적으로 명시하고, 이를 뒷받침할 수 있는 근거자료를 첨부해야 합니다. url, 게시물, 댓글 등의 캡처본을 제출하여야 합니다. 위 커뮤니티 정책의 내용(주요 법령 및 판례)을 참고하시면 작성에 도움이 되며, 명백한 침해사실에 판결 등이 없는 경우 필요 시 법적 권리구제절차가 개시되었음을 증빙할 수 있는 서류(소장, 고소장 등) 제출이 요구될 수 있습니다.</p>
								<strong class="title">(3) 특별 기재 사항</strong>
								<p>신고인의 권리를 직접 침해하는 경우, 신고 내용의 사실 여부에 따라 법적 절차가 진행될 수 있습니다. 신고인이 커뮤니티 정책에서 정한 권리를 보유한 자임을 증빙할 수 있는 서류(신분증 사본 등)의 권리자 또는 권리자의 대리인임을 입증할 수 있는 자료(위임장 등) 제출이 요구될 수 있습니다.</p>
								<strong class="title">8.2. 신고 유의사항</strong>
								<p>신고인은 허위사실을 바탕으로 신고서를 작성하거나, 타인을 비방하기 위한 목적으로 신고서를 제출하는 경우 가치삽시다 서비스 이용 제한 또는 관계 법령에 의한 법적책임을 부담하게 될 수 있습니다.</p>
								<strong class="title">8.3. 신고 철회</strong>
								<p>신고인은 신고인의 오인 혹은 변심으로 인해 신고를 철회할 수 있습니다. 신고 철회 의사는 가치삽시다 고객센터를 통해 철회할 수 있습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">9. 신고확인 및 조치 절차</h4>
								<p>가치삽시다는 신고서가 접수된 이후 다음과 같이 신고 확인 및 조치 절차를 마련하고 있습니다.</p>
								<strong class="title">9.1. 신고요건 검토</strong>
								<p>가치삽시다는 신고가 접수되면 신고 요건 및 신고 내용을 확인합니다. 필수 기재사항에 대한 허위기재 또는 중요사실 누락 등 신고요건이 충족되지 않은 경우 무효 처리되어 후속절차가 진행되지 않으며, 신고요건은 충족하였으나 근거자료가 불충분한 경우 추가 근거자료를 요청할 수 있습니다.</p>
								<strong class="title">9.2. 사실확인</strong>
								<p>가치삽시다 관리자는 제출된 근거자료를 검토하여 해당 콘텐츠가 커뮤니티 정책을 위반했는지 여부를 판단하게 됩니다. 단, 판단이 어려운 경우 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라 임시조치를 취할 수 있으며, 신고내용에 대한 사실확인 요청을 할 수 있습니다.</p>
								<strong class="title">9.3. 후속조치</strong>
								<p>가치삽시다는 커뮤니티 정책을 명백히 위반하는 댓글, 새소식 등 게시물이 확인될 경우 지체없이 신고대상 게시물(댓글, 새소식 등 포함)을 삭제 조치할 수 있으며, 반복적으로 위법 부당 행위가 확인된 이용자는 가치삽시다 서비스 이용이 제한될 수 있습니다.</p>
								<strong class="title">(ⅰ) 댓글, 새소식 등 게시물에 대한 임시조치</strong>
								<p>가치삽시다는 커뮤니티 정책 위반이 의심되는 게시물(댓글, 새소식 등 포함)이 확인될 경우, 커뮤니티 정책 위반 여부를 판단하기 위하여 임시적으로 30일 이내의 기간내에서 해당 게시물(댓글, 새소식 등 포함)에 대한 접근을 임시적으로 차단하는 조치를 할 수 있습니다.</p>
								<strong class="title">(ⅱ) 댓글, 새소식 등 게시물에 대한 반박내용 게재</strong>
								<p>가치삽시다는 커뮤니티 정책 중 명예훼손, 모욕에 해당하는 댓글, 새소식 등 게시물에 대하여 이로 인해 피해를 주장하는 당사자의 요청에 따라 반박내용의 게재를 할 수 있습니다.</p>
								<strong class="title">9.4. 관련 공지</strong>
								<p>9.2. 사실확인 요청 항목에 대해 신고인이 소명하지 않은 경우, 가치삽시다는 신고내용과 사실확인 요청 사실에 대하여 공지할 수 있으며, 신고인에게 추가 근거자료를 요청한 경우 해당 내용에 대하여도 함께 공지할 수 있습니다. 또한 가치삽시다는 회원보호 목적으로 공지의 필요성이 인정되는 경우 피신고인의 소명내용 등 사실확인된 내용과 커뮤니티 정책 위반에 대한 조치계획, 조치내용 등에 관하여 공지할 수 있습니다.</p>
							</section>
							<section class="terms-section">
								<h4 class="terms-section-title">10. 준거법 및 재판관할</h4>
								<p>10.1. 가치삽시다와 이용자간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
								<p>10.2. 본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우 중소기업유통센터 소재지를 관할하는 법원을 관할 법원으로 합니다.</p>
							</section>
							<section class="terms-section">
								<h3 class="terms-section-title">별첨</h3>
								<p>□ 커뮤니티 정책의 내용</p>
								<p>가치삽시다의 커뮤니티 정책은 표현의 자유를 보장하는 한편, 개인의 인격권, 명예권, 개인정보에 관한 권리 등이 침해되지 않도록 헌법의 기본권 규정, 형법의 명예훼손, 모욕 관련 규정, 개인정보보호법의 개인정보 보호규정 등 관련 법령 및 판례에 근거하여 작성되었습니다. 아래 기준에 따라 커뮤니티 정책 위반 여부가 판단되므로, 신고서 접수 및 소명서 제출 시 참고하시기 바랍니다.</p>
								<section class="terms-section">
									<h4 class="terms-section-title">1. 명예훼손</h4>
									<strong class="title">(1) 개념</strong>
									<p>
										명예훼손이란 공연히 사실 또는 허위모실을 적시하여 타인의 명예를 훼손하는 경우를 말합니다. 비방할 목적을 가지고 출판물이나 정보통신망을 통하여 타인의 명예를 훼손한 경우에는 가중처벌 됩니다. 정보통신망에 유통되는 정보가 타인의 명예를 훼손한다고 인정되면 그 침해를 받은 자는 정보통신서비스 제공자에게 침해사실을 소명하여 그 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다.<br />
										명예훼손의 성립요건인 ‘공연성’이란 ‘불특정 또는 다수인이 인식할 수 있는 상태’를 말하며, ‘사실의 적시’란 가치판단이나 주관적 평가가 아닌 ‘표현내용이 증거에 의해 입증이 가능한 것’을 말합니다. 예컨대, 특정한 음식점의 음식이 맛이 없다, 특정한 제품의 품질이 좋지 않다 등의 주관적 평가는 명예훼손죄에서 말하는 사실의 적시에 해당하지 않습니다.
									</p>
									<strong class="title">(2) 관련 법령 및 참고 판례</strong>
									<p>명예훼손(형법 제307조), 사자의 명예훼손(형법 제308조), 출판물 등에 의한 명예훼손(형법 제309조), 위법성의 조각(형법 제310조), 고소와 피해자의 의사(형법 제312조), 정보통신망을 통한 명예훼손(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제70조), 정보의 삭제요청 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의2), 임의의 임시조치 및 자율규제 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의3 및 제44조의4) 명예훼손죄 성립에 필요한 ‘사실의 적시’의 의미 및 판단할 진술이 사실인가 또는 의견인가를 구별하는 방법(대법원 2017. 12. 5. 선고 2017도15628 판결), 명예훼손죄의 구성요건인 공연성의 의미 및 한 사람에게 사실을 유포하였더라도 불특정 또는 다수인에게 전파될 가능성이 있으면 공연성의 요건을 충족하는지 여부(대법원 2017. 9. 7. 선고 2016도15819 판결), ‘사람을 비방할 목적’의 의미와 판단 기준, 표현의 자유와 명예보호 사이의 한계를 정할 때 고려하여야 할 사항(대법원 2018. 11. 29. 선고 2016도14678 판결).</p>
								</section>
								<section class="terms-section">
									<h4 class="terms-section-title">2.모욕</h4>
									<strong class="title">(1) 개념</strong>
									<p>
										모욕이란 공연히 타인을 모욕하는 것으로, 사실을 적시하지 아니하고 사람의 사회적 평가를 저하시킬 만한 추상적 판단이나 경멸적 감정을 표현하는 것을 의미합니다. ‘사실의 적시’가 아닌 ‘추상적 판단이나 경멸적 감정표현’을 통하여 타인의 사회적 평가를 저하시키는 것을 의미하므로 명예훼손과 구별됩니다.<br />
										단순히 무례한 행동은 모욕이 아니며, 불특정 다수인에게 전파될 가능성이 없는 상태에서의 표현은 공연성이 인정되지 않으므로 모욕죄가 성립하지 않습니다. 정보통신망에 유통되는 정보가 타인을 모욕하는 내용을 포함하고 있는 경우 그 침해를 받은 자는 정보통신서비스 제공자에게 침해사실을 소명하여 그 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다.
									</p>
									<strong class="title">(2) 관련 법령 및 판례</strong>
									<p>모욕(형법 제311조), 고소와 피해자의 의사(형법 제312조), 정보의 삭제요청 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의2), 임의의 임시조치 및 자율규제 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의3 및 제44조의4) 모욕죄에서 말하는 ‘모욕’의 의미, 상대방의 인격적 가치에 대한 사회적 평가를 저하시킬 만한 것이 아닌 표현이 다소 무례한 방법으로 표시된 경우 모욕죄의 구성요건에 해당하는지 여부(대법원 2018. 11. 29. 선고 2017도2661 판결), 인터넷 신문상의 특정 기사에 댓글형식으로 그 기사에 등장하는 특정인에 대하여 경멸의 의사를 표시하는 글을 게재하는 행위가 모욕죄에 해당한다고 한 사례(서울중앙지방법원 2006. 3. 10. 선고 2006고정885 판결)</p>
								</section>
								<section class="terms-section">
									<h4 class="terms-section-title">3. 개인정보침해</h4>
									<strong class="title">(1) 개념</strong>
									<p>
										개인정보란 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보로서 해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함합니다.<br />
										예컨대, (ⅰ) 개인식별 정보로서 주민등록번호, 사회보장번호, 여권 번호, 수험표 번호, 정부 발급 신분증, 학생증, 비밀번호를 포함한 디지털 정보 등, (ⅱ) 개인 금융정보로서 은행 계좌 정보, 카드 정보 등, (ⅲ) 개인의 거주지관련 정보로서 주소, 거주자의 주소가 노출될 수 있는 거주지 이미지 파일 등, (ⅳ) 기타 외부 링크를 통해 개인식별 정보를 공유하여 신원 도용에 이용될 수 있는 콘텐츠 등이 개인정보로서 보호되며, 이러한 개인정보를 정보주체의 동의 없이 함부로 유출할 경우 민사상 손해배상 책임, 형사상 책임 등 법적 책임이 발생할 수 있습니다. 정보통신망에 유통되는 정보가 타인의 사생활 및 개인정보에 대한 권리를 침해하는 내용을 포함하고 있는 경우 그 침해를 받은 자는 정보통신서비스 제공자에게 침해사실을 소명하여 그 정보의 삭제 또는 반박내용의 게재를 요청할 수 있습니다.
									</p>
									<strong class="title">(2) 관련 법령</strong>
									<p>개인정보의 개념(개인정보보호법 제2조), 개인정보를 처리하거나 처리하였던 자의 개인정보 유출 등 금지행위(개인정보보호법 제59조), 정보주체의 동의 없이 개인정보를 제3자에게 제공한 자에 대한 형사처벌 규정(개인정보보호법 제71조), 정보의 삭제요청 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의2), 임의의 임시조치 및 자율규제 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의3 및 제44조의4)</p>
								</section>
								<section class="terms-section">
									<h4 class="terms-section-title">4.기타 - 부적절 콘텐츠 게재 등</h4>
									<strong class="title">(1) 개념</strong>
									<p>
										예컨대, (ⅰ) 인종, 민족, 국적, 종교, 정치 성향, 사상, 성적 취향, 성별 또는 신체적 장애 등을 이유로 부당한 차별을 하거나 직접적으로 공격하는 내용의 콘텐츠, (ⅱ) 노골적인 성행위 묘사, 나체 이미지 등의 과도한 노출이 포함된 음란물 콘텐츠 또는 포르노 사이트로 트래픽을 유도하는 콘텐츠, (ⅲ) 사행행위 등 범죄를 교사하거나 방조하는 내용의 콘텐츠 등 불법 콘텐츠를 게재하는 경우 법적 책임이 발생할 수 있습니다. (ⅳ) 가치삽시다의 사전 허락 없이 자동화된 수단(예: 매크로 프로그램, 로봇(봇), 스파이더, 스크래퍼 등)을 이용하여 게재한 콘텐츠, (ⅴ) 가치삽시다 각 개별 서비스의 제공 취지와 부합하지 않거나 그 제공되는 서비스와 전혀 관련이 없는 내용의 콘텐츠, (ⅵ) 물건 또는 서비스를 홍보, 광고 또는 판매할 목적으로 동일 또는 유사한 내용의 게시물로서 지속적으로 반복하여 게재함으로써 다른 이용자에게 심각한 불편을 초래하는 콘텐츠, (ⅶ) 타인을 사칭하였거나 허위사실을 주장함이 확인된 콘텐츠 등 불법 또는 부적절한 콘텐츠를 게재하는 경우 법적 책임이 발생할 수 있으며, 가치삽시다에 의한 삭제 등의 조치가 이루어질 수 있습니다.<br />
										정보통신서비스 제공자는 자신이 운영관리하는 정보통신망에 청소년유해매체물이 게재되어 있거나 청소년 접근을 제한하는 조치 없이 청소년유해매체물을 광고하는 내용이 전시되어 있는 경우에는 지체 없이 그 내용을 삭제하여야 합니다.
									</p>
									<strong class="title">(2) 관련 법령</strong>
									<p>인간의 존엄 및 행복추구권(헌법 제10조), 평등권(헌법 제11조), 불법정보의 유통금지 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의7), 불법정보의 유통금지 등에 대한 형사처벌 규정(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제74조), 장애인에 대한 괴롭힘 등의 금지 및 위반 시 형사처벌 규정(장애인차별금지 및 권리구제 등에 관한 법률 제32조 및 제49조) 정보의 삭제요청 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의2), 임의의 임시조치 및 자율규제 등(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의3 및 제44조의4), 영리목적의 광고성 정보 전송 제한(정보통신망 이용촉진 및 정보보호 등에 관한 법률 제50조), 영리목적의 광고성 정보 전송기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 시행령 제61조)</p>
								</section>
							</section>
						</div>
						<!-- e: 이용정책 내용 -->
					</div>
					<div class="popup-buttons">
						<p class="desc">가치삽시다 소상공인 커뮤니티 서비스 운영정책에 동의합니다. 동의하지 않을 경우 서비스 이용이 제한됩니다.</p>
						<button type="button" class="button-default is-primary is-rounded" :class="{ 'is-disabled': !scrollEnd }" @click="insertSCommunityUser()">
							<span class="text">동의</span>
						</button>
						<button type="button" class="button-default is-light is-rounded" @click="termsShowClose()">
							<span class="text">비동의</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ACT_GET_PROG_SCHEDULE_LIST } from '@/store/_act_consts';
import { getItems } from '@/assets/js/utils';

export default {
	name: 'TermsAgreeModal',
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('common', ['isMobile']),
	},
	data: () => ({
		scrollEnd: false,
	}),
	created() {},
	mounted() {
		document.querySelector('.terms-wrap').addEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll(e) {
			let scrollTop = document.querySelector('.terms-wrap').scrollTop;
			const scrollHeight = document.querySelector('.terms-wrap').scrollHeight;
			//console.log('scrollHeight :', scrollHeight);
			if (scrollTop + 1000 > scrollHeight) {
				this.scrollEnd = true;
			}
			//console.log('scrollTop :', scrollTop);
		},
		insertSCommunityUser() {
			if (this.scrollEnd) {
				this.$emit('insertSCommunityUser');
			}
		},
		termsShowClose() {
			this.$emit('termsShowClose');
		},
	},
	unmounted() {
		document.querySelector('.terms-wrap').removeEventListener('scroll', this.handleScroll);
	},
};
</script>

<style lang="scss" scoped>
.layer-popup {
	&-terms {
		padding-left: clamp(15px, 4vw, 140px);
		padding-right: clamp(15px, 4vw, 140px);
		text-align: left;
		font-size: 14px;
		line-height: 1.5;
		.popup-header .btn-close {
			right: 0;
		}
		.popup-buttons {
			gap: 15px;
			align-items: center;
			padding: 1.5em 0;
			@include mobile {
				flex-wrap: wrap;
			}
			.desc {
				font-size: 16px;
				flex: 1 1;
				@include mobile {
					flex: inherit;
					width: 100%;
				}
			}
			.button-default {
				height: 40px;
				border: 1px solid #645f5b;
				flex: 0 0 100px;
				@include transition(opacity, 0.5s);
				&:hover,
				&:focus {
					background-color: #444;
				}
				&.is-light {
					&:hover,
					&:focus {
						border-color: #111;
						background-color: transparent;
					}
				}
				&.is-disabled {
					opacity: 0.3;
					pointer-events: none;
				}
			}
		}
	}
	.popup-body {
		padding: 0;
	}
	.terms-wrap {
		padding: 2em;
		overflow-y: auto;
		max-height: calc(100vh - 300px);
		border: 1px solid #e1e1e1;
		background-color: #fff;
		@include tablet {
			max-height: calc(100vh - 220px);
		}
		p {
			margin-bottom: 1em;
		}
	}
	.terms-section {
		margin-bottom: 2em;
		&-title {
			margin-bottom: 0.5em;
			font-size: 18px;
			font-weight: 700;
		}
	}
}
</style>
